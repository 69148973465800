.App {
  text-align: center;
  box-sizing: border-box;
  background-color:var(--bg);

}
:root {
  --text-dark: #696e79;
  --text-white: #ffffff;
  --main-bg:#0e0610;
  --bg: #000000;
  --cta: #01c38d;
}
div {
  box-sizing: border-box;
  display: block;
}
